import * as React from 'react';
import Layout from '../components/Layout';
import Chart from '../components/Chart';
import RefDataDate from '../components/RefDataDate';

import NEA_Edu_Expenditure_EN from '/images/NEA_Edu_Expenditure.png';
import NEA_Edu_Expenditure from '/images/NEA_Edu_Expenditure_EN.png';

export default function Nea() {
  const [translate, setTranslate] = React.useState(null)
  const [lang, setLang] = React.useState(null)
  let data1 = [
    {
      name: translate?.NEA_EDU_BUDGET,
      color: '#2ABDAC',
      data: [354634, 419233, 379125, 423563, 444484, 493892]
    },
    {
      name: translate?.NEA_GOV_EDU_BUDGET,
      color: '#54CC5C',
      data: [421652, 477510, 521233, 537885, 587767, 646229]
    }, {
      name: translate?.NEA_GOV_EDU_EXPENDITURE,
      color: '#095A00',
      data: [564869, 610798, 658098, 675918, 753325, 805239]
    }
  ]

  let data2 = [
    {
      name: translate?.NEA_Expenditure_GDP,
      color: '#2ABDAC',
      data: [4.02, 4.64, 3.75, 4.02, 3.84, 3.94]
    }, {
      name: translate?.NEA_GOV_EDU_BUDGET_GDP,
      color: '#54CC5C',
      data: [4.64, 5.28, 5.16, 5.10, 5.08, 5.15]
    }, {
      name: translate?.NEA_GOV_EDU_EXPENDITURE_GDP,
      color: '#095A00',
      data: [6.22, 6.76, 6.51, 6.41, 6.51, 6.42]
    }
  ]


  let data3_all = [580000, 620000, 660000, 670000, 750000, 820000]
  let data3 = [
    {
      name: translate?.NEA_GFMIS,
      color: '#008C68',
      data: [60.5, 63.1, 64.4, 64.5, 63, 63.4].map((item, id) => { return { y: (data3_all[id] * item) / 100, per: item } }),
      stack: 'source'
    },
    {
      name: translate?.NEA_DLA,
      color: '#00D19C',
      data: [14.1, 15.1, 14.6, 15, 15, 16.9].map((item, id) => { return { y: (data3_all[id] * item) / 100, per: item } }),
      stack: 'source'
    },
    {
      name: translate?.NEA_Household,
      color: '#CCF2E8',
      data: [26.6, 21.1, 20, 19.8, 20.7, 19].map((item, id) => { return { y: (data3_all[id] * item) / 100, per: item } }),
      stack: 'source'
    },
    {
      name: translate?.NEA_Private_NGO_ROW,
      color: '#F0AB7D',
      data: [0.8, 0.8, 0.8, 0.6, 1.3, 0.8].map((item, id) => { return { y: (data3_all[id] * item) / 100, per: item } }),
      stack: 'source'
    },
  ]

  let data4_all = [580000, 620000, 660000, 670000, 750000, 820000]
  let data4 = [
    {
      name: translate?.NEA_PRE_PRIAMRY,
      data: [9, 9, 9, 12, 13, 13].map((item, id) => { return { y: (data4_all[id] * item) / 100, per: item } }),
      stack: 'source'
    },
    {
      name: translate?.NEA_PRIAMRY,
      color: '#00D19C',
      data: [30, 28, 31, 30, 31, 31].map((item, id) => { return { y: (data4_all[id] * item) / 100, per: item } }),
      stack: 'source'
    },
    {
      name: translate?.NEA_HIGH_SCHOOL,
      color: '#CCF2E8',
      data: [23, 23, 26, 21, 22, 20].map((item, id) => { return { y: (data4_all[id] * item) / 100, per: item } }),
      stack: 'source'
    },
    {
      name: translate?.NEA_VC,
      color: '#4188F3',
      data: [6, 6, 7, 7, 6, 6].map((item, id) => { return { y: (data4_all[id] * item) / 100, per: item } }),
      stack: 'source'
    },
    {
      name: translate?.NEA_UNIVERSITY,
      color: '#FFE99B',
      data: [25, 24, 22, 23, 21, 20].map((item, id) => { return { y: (data4_all[id] * item) / 100, per: item } }),
      stack: 'source'
    },
    {
      name: translate?.NEA_OTHER,
      color: '#AE6900',
      data: [7, 10, 5, 7, 7, 10].map((item, id) => { return { y: (data4_all[id] * item) / 100, per: item } }),
      stack: 'source'
    },
  ]

  let data5 = [
    {
      name: translate?.NEA_PRE_PRIAMRY,
      color: '#03C4FF',
      data: [2.64, 3.12, 1.67, 0, 0, 12.32],
      stack: 'source'
    },
    {
      name: translate?.NEA_PRIAMRY,
      color: '#F5858D',
      data: [34.11, 30.03, 29.27, 20.24, 50.49, 32.44],
      stack: 'source'
    },
    {
      name: translate?.NEA_HIGH_SCHOOL,
      color: '#8F5C99',
      data: [38.24, 37.88, 30.55, 23.83, 23.54, 27.95],
      stack: 'source'
    },
    {
      name: translate?.NEA_UNIVERSITY_2,
      color: '#243F8F',
      data: [19.47, 15.61, 36.97, 35.63, 19.12, 18.97],
      stack: 'source'
    },
    {
      name: translate?.NEA_OTHER,
      color: '#67C18C',
      data: [5.53, 13.36, 1.53, 20.29, 6.85, 8.32],
      stack: 'source'
    },
  ]

  return (
    <Layout
      Role={['public-login', 'partner', 'eef', 'partner_province']}
      getLang={(translate, lang) => {
        setTranslate(translate)
        setLang(lang)
      }}
    >
      <div>
        <div className='h-[120px]'></div>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-black w-full ">{translate?.NEA_Project_TITLE_1}</h1>
        <h1 className="font-bold text-xl md:text-3xl xl:text-5xl text-center text-black w-full ">{translate?.NEA_Project_TITLE_2}</h1>
        <div className="max-w-[1300px] 2xl:max-w-[1400px] rounded-xl m-auto p-8 bg-[#F6F4F4] bordered font-['Bai Jamjuree']">
          <h2 className="font-bold text-base md:text-lg xl:text-xl text-center text-black w-full" style={{ fontFamily: 'Bai Jamjuree' }}>
            {translate?.NEA_Project_Description}
          </h2>
        </div >
        <div className="max-w-[1300px] 2xl:max-w-[1400px] m-auto p-2">
          <div className="grid grid-cols-12 gap-4 w-full">
            <div className="col-span-12 lg:col-span-6 h-[860px] my-2">
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-1/2 p-4">
                <div className="font-bold text-xl mb-2 text-[#0E3165] text-center"> {translate?.NEA_Expenditure}</div>


                <Chart
                  options={{
                    legend_enabled: false,
                    tooltip: {
                      shared: true,
                      crosshairs: true
                    },
                    tooltip: {
                      shared: true,
                      crosshairs: true
                    },
                    legend: {
                      // layout: 'vertical',
                      align: 'center',
                      verticalAlign: 'bottom'
                    },
                    yAxis: {
                      title: {
                        text: lang === 'EN' ? 'bath' : 'บาท'
                      }
                    },
                    title: '',
                    type: 'line',

                    categories: [
                      translate?.Y2551,
                      translate?.Y2552,
                      translate?.Y2553,
                      translate?.Y2554,
                      translate?.Y2555,
                      translate?.Y2556
                    ]
                  }}
                  containerProps={{ style: { height: "275px" } }}
                  style={{ height: '285px' }}
                  data={data1}
                />
                <div className="font-bold text-sm md:text-base text-[#924848] text-letf" style={{ fontFamily: 'Bai Jamjuree' }}> {translate?.NEA_Expenditure_Description}</div>
              </div>
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-1/2 p-4 mt-[16px]">
                <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">{translate?.NEA_GDP}</div>
                <Chart
                  options={{
                    legend_enabled: false,
                    tooltip: {
                      shared: true,
                      crosshairs: true
                    },
                    tooltip: {
                      shared: true,
                      crosshairs: true
                    },
                    legend: {
                      // layout: 'vertical',
                      align: 'center',
                      verticalAlign: 'bottom'
                    },
                    yAxis: {
                      title: {
                        text: translate?.NEA_PERCENTAGE
                      }
                    },
                    title: '',
                    type: 'line',
                    categories: [
                      translate?.Y2551,
                      translate?.Y2552,
                      translate?.Y2553,
                      translate?.Y2554,
                      translate?.Y2555,
                      translate?.Y2556
                    ]
                  }}
                  containerProps={{ style: { height: "275px" } }}
                  style={{ height: '285px' }}
                  data={data2}
                />
                <div className="font-bold text-sm md:text-base text-[#924848] text-letf" style={{ fontFamily: 'Bai Jamjuree' }}> {translate?.NEA_GDP_Description}</div>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 h-[500px] md:h-[1000px] lg:h-[880px] my-2">
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full p-4">
                <div className="font-bold text-xl mb-2 text-[#0E3165] text-left">{translate?.EDUCATIONAL_EXPENSES_2553} </div>
                <div className="font-bold text-base mb-2 text-black text-left">{translate?.EDUCATIONAL_EXPENSES_AMOUNT_2553} </div>
                <img src={lang === 'EN' ? NEA_Edu_Expenditure_EN : NEA_Edu_Expenditure} alt="" className="w-full h-full" />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 h-[725px] md:h-[625px] my-2">
              {/* {translate?.NEA_GDP_Description}</div>NEA_Sources_Description_1 */}
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full p-4">
                <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">{translate?.NEA_Sources}</div>
                <Chart
                  options={{
                    title: '',
                    type: 'stackedAndColumn',
                    xAxis: {
                      categories: ['2551', '2552', '2553', '2554', '2555', '2556'],
                    },
                    yAxis: {
                      min: 0,
                      max: 900000,
                      tickInterval: 100000,
                      gridLineInterpolation: 'polygon',
                      lineWidth: 0,
                      title: {
                        text: lang === 'EN' ? 'million baht' : 'ล้านบาท'
                      },
                      // stackLabels: {
                      //   enabled: true,
                      //   style: {
                      //     fontWeight: 'bold',
                      //     color: 'black',
                      //     textOutline: 'none'
                      //   }
                      // }
                    },
                    tooltip: {
                      formatter: function () {
                        return '<b>' + this.x + '</b><br/>' +
                          this.series.name + ': ' + this.point.per + '%<br/>'
                      }
                    },
                    plotOptions: {
                      column: {
                        stacking: 'normal',
                        dataLabels: {
                          enabled: true,
                          formatter: function () {
                            return this.point.per + '%'
                          }
                        }
                      },

                    },
                  }}
                  containerProps={{ style: { height: "400px" } }}
                  style={{ width: '100px', height: '400px' }}
                  data={data3.reverse()}
                />
                <div className="font-bold text-xs text-[#924848] text-left" style={{ fontFamily: 'Bai Jamjuree' }}>{translate?.NEA_Sources_Description_1}</div>
                <div className="font-bold text-xs text-black text-left" style={{ fontFamily: 'Bai Jamjuree' }}>{translate?.NEA_Sources_Description_2}</div>

              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 h-[725px] md:h-[625px] my-2">
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full p-4">
                <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">{translate?.NEA_Expenditures}</div>
                <Chart
                  options={{
                    title: '',
                    type: 'stackedAndColumn',
                    xAxis: {
                      categories: [
                        translate?.Y2551,
                        translate?.Y2552,
                        translate?.Y2553,
                        translate?.Y2554,
                        translate?.Y2555,
                        translate?.Y2556
                      ],
                    },
                    yAxis: {
                      min: 0,
                      max: 900000,
                      tickInterval: 100000,
                      gridLineInterpolation: 'polygon',
                      lineWidth: 0,
                      title: {
                        text: lang === 'EN' ? 'million baht' : 'ล้านบาท'
                      },
                      // stackLabels: {
                      //   enabled: true,
                      //   style: {
                      //     fontWeight: 'bold',
                      //     color: 'black',
                      //     textOutline: 'none'
                      //   }
                      // }
                    },
                    tooltip: {
                      formatter: function () {
                        return '<b>' + this.x + '</b><br/>' +
                          this.series.name + ': ' + this.point.per + '%<br/>'
                      }
                    },
                    plotOptions: {
                      column: {
                        stacking: 'normal',
                        dataLabels: {
                          enabled: true,
                          formatter: function () {
                            return this.point.per + '%'
                          }
                        }
                      },

                    },
                  }}
                  containerProps={{ style: { height: "400px" } }}
                  style={{ width: '100px', height: '400px' }}
                  data={data4.reverse()}
                />
                <div className="font-bold text-base text-[#924848] text-center" style={{ fontFamily: 'Bai Jamjuree' }}>{translate?.NEA_Expenditures_Description}</div>
              </div>
            </div>

            <div className="col-span-12 lg:col-span-12 h-[720px] md:h-[600px] my-2">
              <div className="w-full bg-[#FFFFFF] border-[1px] border-solid mx-auto rounded-xl shadow-xl h-full p-4">
                <div className="font-bold text-xl mb-2 text-[#0E3165] text-center">{translate?.NEA_Education_Expenditures}</div>
                <Chart
                  options={{
                    title: '',
                    type: 'column',
                    xAxis: {
                      categories: [
                        translate?.NEA_JAPAN,
                        translate?.NEA_SOUTH_KOREA,
                        translate?.NEA_MALAYSIA,
                        translate?.NEA_SINGAPORE,
                        translate?.NEA_MYANMAR,
                        translate?.NEA_THAILAND
                      ],
                    },
                    yAxis: {
                      min: 0,
                      max: 60,
                      tickInterval: 10,
                      lineWidth: 0,
                      title: {
                        text: translate?.NEA_PERCENTAGE
                      },
                      // stackLabels: {
                      //   enabled: true,
                      //   style: {
                      //     fontWeight: 'bold',
                      //     color: 'black',
                      //     textOutline: 'none'
                      //   }
                      // }
                    },
                    tooltip: {
                      formatter: function () {
                        return '<b>' + this.x + '</b><br/>' +
                          this.series.name + ': ' + this.y + '%<br/>'
                      }
                    },
                    plotOptions: {
                      column: {
                        dataLabels: {
                          enabled: true,
                          rotation: 270,
                          y: -20,
                          formatter: function () {
                            return this.y + '%'
                          }
                        }
                      },

                    },
                  }}
                  containerProps={{ style: { height: "400px" } }}
                  style={{ width: '100px', height: '400px' }}
                  data={data5}
                />
                {
                  lang === 'EN' ?
                    <>
                      <div className="text-base text-gray-600 text-left">Remark: "Total local budget"</div>
                      <div className="text-base text-gray-600 text-left">Ref: 1. UIS data center, 2015 2. Thailand information from NEA</div>
                    </>
                    :
                    <>
                      <div className="text-base text-gray-600 text-left">หมายเหตุ: "รวมงบประมาณของท้องถิ่น"</div>
                      <div className="text-base text-gray-600 text-left">ที่มา: 1. UIS data center, 2015 2. ข้อมูลประเทศไทยจาก NEA</div>
                    </>
                }

                <div className="font-bold text-base text-[#924848] text-center" style={{ fontFamily: 'Bai Jamjuree' }}>{translate?.NEA_Education_Expenditures_Description}</div>
              </div>
            </div>
          </div>
          <RefDataDate
            title=""
            subjectTitle={translate?.PROCESSED_APR_2565}
          />
        </div>

        <div className='h-[100px]'></div>
      </div>
    </Layout >
  );
}